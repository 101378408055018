
import { Component, Vue, Ref, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { FarmingType, FarmingList, FormList, JobContentList } from '@/types/farmingCalendar'

@Component({

})
export default class Guide extends Vue {
    loading = false
    tableData: FarmingList[] = []

    dialogVisible1 = false
    farmingTypeData: Array<FarmingType> = []
    typeLoading = false
    isAddFarmingType: number | null = null

    titleDig = '查看农事指导'
    isEdit = true
    dialogVisible2 = false
    jobLoading = false

    @Watch('dialogVisible1')
    typeShowChange (value: boolean) {
      value && this.getFarmingType()
    }

    @Ref('form') readonly form!: ElForm
    formData: FormList = {
      templateCalendarId: '',
      farmingExplain: '',
      farmingJobTemplateList: []
    }

    rules = {
      templateCalendarId: [
        { required: true, message: '请选择作业月份', trigger: 'blur' }
      ]
    }

    created () {
      this.getTableData()
      this.getFarmingType()
    }

    // 获取列表数据
    getTableData () {
      this.loading = true
      this.$axios.get<{ list: Array<FarmingList> }>(this.$apis.calendar.selectFarmingCalendarTemplateByPage).then(res => {
        this.tableData = res.list || []
      }).finally(() => {
        this.loading = false
      })
    }

    // 农事类型弹窗
    showFarmingType () {
      this.dialogVisible1 = true
      this.getFarmingType()
    }

    // 获取农事类型列表
    getFarmingType () {
      this.typeLoading = true
      this.$axios.get(this.$apis.calendar.selectFarmingTypeByList).then(res => {
        this.farmingTypeData = res.list || []
      }).finally(() => {
        this.typeLoading = false
      })
    }

    // 添加类型
    onAdd () {
      if (this.isAddFarmingType !== null) {
        this.$message.warning('请先保存！')
      } else {
        this.farmingTypeData.push({
          typeName: '',
          isInput: true
        })
        this.isAddFarmingType = this.farmingTypeData.length - 1
        this.$nextTick(() => {
          (this.$refs.table as any).bodyWrapper.scrollTop = (this.$refs.table as any).bodyWrapper.scrollHeight
        }) // 滚动到表格最底部
      }
    }

    // 保存编辑类型
    onSubmit (row: FarmingType) {
      if (row.typeName) {
        const info = { typeName: row.typeName }
        const url = row.typeId ? this.$apis.calendar.updateFarmingType : this.$apis.calendar.insertFarmingType
        this.$axios.post(url, row.typeId ? { ...info, typeId: row.typeId } : info).then(() => {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.isAddFarmingType = null
          this.getFarmingType()
        })
      } else {
        this.$message({
          message: '请填写完整',
          type: 'warning'
        })
      }
    }

    // 编辑类型
    onUpdate (row: FarmingType, index: number) {
      if (this.isAddFarmingType != null) {
        this.$message.warning('请先保存！')
      } else {
        this.farmingTypeData[index] = {
          ...row,
          isInput: true
        }
        this.isAddFarmingType = index
        this.$set(this.farmingTypeData, index, this.farmingTypeData[index]) // 重新渲染表格数据
      }
    }

    // 取消
    onCancel (row: FarmingType, index: number) {
      this.isAddFarmingType = null
      if (row.typeId) {
        // 是否为编辑
        this.farmingTypeData[index].isInput = false
      } else {
        this.farmingTypeData.splice(index, 1)
      }
    }

    // 删除类型
    onDelete (typeId: string) {
      this.$confirm('确认删除吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.typeLoading = true
        this.$axios.post(this.$apis.calendar.deleteFarmingType, { typeId }).then(() => {
          this.$message.success('删除成功')
          this.isAddFarmingType = null
          this.getFarmingType()
        }).finally(() => {
          this.typeLoading = false
        })
      })
    }

    // 关闭农事类型弹窗
    closeDialog () {
      // 数据清除
      this.isAddFarmingType = null
      this.dialogVisible1 = false
    }

    // 查看农事指导
    detailShow (row: FarmingList) {
      this.formData.templateCalendarId = row.templateCalendarId
      this.titleDig = '查看农事指导'
      this.isEdit = false
      this.dialogVisible2 = true
      this.getFarmingDetail()
    }

    // 编辑农事指导
    editShow (row: FarmingList) {
      this.titleDig = '编辑农事指导'
      this.formData.templateCalendarId = row.templateCalendarId
      this.isEdit = true
      this.dialogVisible2 = true
      this.getFarmingDetail()
    }

    // 获取农事指导详情
    getFarmingDetail () {
      this.jobLoading = true
      this.$axios.get<JobContentList>(this.$apis.calendar.selectFarmingCalendarTemplate, { templateCalendarId: this.formData.templateCalendarId }).then(res => {
        this.formData = res || []
        this.formData.farmingExplain = res.farmingExplain || ''
        this.formData.farmingJobTemplateList = res.farmingJobTemplateList || []
      }).finally(() => {
        this.jobLoading = false
      })
    }

    // 新增作业内容
    addFarmingContent () {
      this.formData.farmingJobTemplateList.push({
        farmingTypeId: '',
        farmingTypeName: '',
        deleteStatus: '1', // 是否可删除 1 可删除
        content: '',
        isInput: false
      })
    }

    // 删除作业内容
    deleteFarmingList (index: number) {
      this.$confirm('确认删除吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.formData.farmingJobTemplateList.splice(index, 1)
      })
    }

    // 编辑农事指导确认
    submit () {
      const form = JSON.parse(JSON.stringify(this.formData)) as FormList
      this.form.validate(valid => {
        if (valid) {
          for (let i = 0; i < form.farmingJobTemplateList.length; i++) {
            delete form.farmingJobTemplateList[i].isInput
            if (form.farmingJobTemplateList[i].farmingTypeId === '') {
              this.$message({ type: 'warning', message: '存在农事类型必填字段未录入' })
              return
            }
          }
          this.$axios.post(this.$apis.calendar.updateFarmingCalendarTemplate, form).then(() => {
            this.dialogVisible2 = false
          }).finally(() => {
            this.getTableData()
          })
        }
      })
    }
}
